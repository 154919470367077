<template>
  <div>
    <CRow>
        <CCol sm="12" class="d-flex justify-content-end">
          <CButton
            shape="square"
            color="add"
            v-c-tooltip="{placement:'top-end', content: $t('label.circuit') }"
            class="d-flex align-items-center"
            @click="toggleModal"
          >
            <CIcon name="cil-playlist-add" /><span class="ml-1">{{
              $t("label.nuevo")
            }}</span>
          </CButton>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            hover
            sorter
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items="formatedItems"
            :fields="fields"
            :loading="loading"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="5"
            :active-page="activePage"
            pagination
          >
            <template #loading>
              <loading />
            </template>
            <template #Status="{ item }">
              <td class="text-center align-middle">
                <div>
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t("label." + item.Status) }}
                  </CBadge>
                </div>
              </td>
            </template>

            <template #Details="{ item }">
              <td class="center-cell">
                <CButtonGroup>
                  <CButton
                    shape="square"
                    color="edit"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.circuit'),
                      placement: 'top-end',
                    }"
                    @click="toggleModal(true, item)"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                </CButtonGroup>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
    </CRow>  
      <circuit-modal
        :modal.sync="modal"
        :circuitId="circuit"
        :edit="edit"
        @submited="handleSubmit"
      />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import CircuitModal from "./circuit-modal";

function fields() {
  return [
    { key: "Nro", label: "#", _style: "width:1%;", _classes: "text-center", filter: false, _classes:'text-center center-cell'},
    { key: "CircuitName", _style: "width:10%;",label: this.$t("label.circuit"),_classes:'text-center center-cell'},
    { key: "Mandatory",_style: "width:10%;",label: this.$t("label.mandatory"),_classes:'text-center center-cell'},
    { key: "Crane",_style: "width:10%;",label: this.$t("label.crane"),_classes:'text-center center-cell'},
    { key: "Machinery",_style: "width:10%;",label: this.$t("label.machinery"),_classes:'text-center center-cell'},
    { key: "Scale",_style: "width:10%;",label: this.$t("label.truckScale"),_classes:'text-center center-cell'},
    { key: "Hold",_style: "width:10%;",label: this.$t("label.hold"),_classes:'text-center center-cell'},
    { key: "Radioactivity",_style: "width:10%;",label: this.$t("label.radioactivity"),_classes:'text-center center-cell'},
    { key: "Yard",_style: "width:10%;",label: this.$t("label.yard"),_classes: "text-center center-cell" },
    { key: "Usuario",_style: "width:5%;", label: this.$t("label.user"),_classes: "text-center center-cell"  },
    { key: "Fecha", _style: "width:5%;",label: this.$t("label.date"), _classes: "text-center center-cell" },
    { key: "Status", _style: "width:5%;",label: this.$t("label.status"), _classes: "text-center center-cell" },
    { key: "Details",_style: "width-min:50px;",label: "",sorter: false,filter: false,_styles: "width: 1%;",_classes:'text-center center-cell'},
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    CircuitName: "align-middle",
    Mandatory: "align-middle",
    Crane: "align-middle",
    Machinery: "align-middle",
    Scale: "align-middle",
    Hold: "align-middle",
    Radioactivity: "align-middle",
    Yard: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    items: [],
    loading: false,
    modal: false,
    edit: false,
    circuit: '',
    activePage: 1,
  };
}

//METHOD
function getCircuitList() {
  this.loading = true;

  this.$http
    .get("Circuit-list", { CompanyBranchId: this.getBranchId })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loading = false;
    });
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.circuit =  item.CircuitId;
  }

  this.modal = true;
}
function handleSubmit(){
  this.getCircuitList();
}

function checkParameter(value) {
  return value ? 'SI' : 'NO';
}

// COMPUTED
function formatedItems() {
  return this.items.map((circuit) =>
    Object.assign({}, circuit, {
      Nro: circuit.Nro,
      CircuitName: this.$i18n.locale == 'es' ? circuit.CircuitNameEs : circuit.CircuitNameEn,
      Mandatory: this.checkParameter(circuit.FgRequired),
      Crane: this.checkParameter(circuit.FgCrane),
      Machinery: this.checkParameter(circuit.FgMachine),
      Scale: this.checkParameter(circuit.FgWeighingScale),
      Hold: this.checkParameter(circuit.FgHold),
      Radioactivity: this.checkParameter(circuit.FgRadioActivityLevel),
      Yard: this.checkParameter(circuit.FgYard),
      Usuario: circuit.TransaLogin ? circuit.TransaLogin : "N/A",
      Fecha: circuit.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(circuit.TransaRegDate)
        : "N/A",
      Status: circuit.Status,
      _classes: circuit.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    })
  );
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

export default {
  name: "circuit-list",
  mixins: [General],
  data,
  props:['tabIndex'],
  components: {
    CircuitModal,
  },
  methods: {
    getCircuitList,
    toggleModal,
    handleSubmit,
    checkParameter,
  },
  watch: {
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.circuit = '';
      }
    },
    tabIndex(newValue){
      if(newValue === 0){
         this.getCircuitList(); 
      }
    }
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.getCircuitList();
  },
  mounted() {},
};
</script>
<style scoped>
.upload-progress {
  width: 100%;
}
</style>