<template>
<div>
    <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t("label.circuitList") }}</b>
        </CCardHeader>
          <CCardBody>
            <CustomTabs :active-tab="tabChange" @update:activeTab="handleTab">
              <CustomTab :title="$t('label.circuit')" icon="brand">
                <Circuit :tabIndex="tabChange" />
              </CustomTab>
              <CustomTab :title="$t('label.modality')" icon="model" >
                <Modality :tabIndex="tabChange" />
              </CustomTab>
            </CustomTabs>
      </CCardBody>
    </CCard>
</div>
</template>

<script>
import { mapState } from "vuex";
import Circuit from "@/pages/metalscrap/circuits/circuit/index";
import Modality from "@/pages/metalscrap/circuits/modality/index";
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';


//DATA
function data() {
  return {
    tabChange: 0,
  };
}

//METHOD
  function handleTab(tab) {

      this.tabChange = tab;
  }


// COMPUTED


export default {
  name: "circuit-list",
  data,
  components: {
    Circuit,
    Modality,
    CustomTabs,
    CustomTab,
  },
  methods: {
    handleTab,
  },
  watch: {

  },
  computed: {
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style scoped>

</style>
